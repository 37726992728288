import React, { useEffect, useRef } from 'react';
import { RESET } from 'jotai/utils';
import { useAtomValue as useSegmentValue, useAtom as useSegment } from 'jotai';
import {
  getSessionDetails,
  validateFileProcess,
} from '../../../store/globalStore/actions/fileUploadActions';
import { fileUploadSegment } from '../../../store/globalStore/stateSegments/fileUploadSegment';
import { useSnackBar } from '../../../hooks/snackBar/useSnackBar';
import SnackBar, {
  SnackBarPosition,
  SnackBarVariant,
} from '../../atoms/SnackBar';
import { generateStateSegment } from '../../../store/markettingContentGeneration/stateSegments/generateStateSegment';
import {
  generateStatus,
  getGeneratedContent,
  getGeneratedContentFormatted,
} from '../../../services/Content';
import {
  ANALYTICS,
  ContentType,
  REGENERATION_LIMIT,
  summarizationStatuses,
  SUMMARIZE,
} from '../../../constants/common';
import { contentListStateSegment } from '../../../store/markettingContentGeneration/stateSegments/contentListSegment';
import store from '../../../store/markettingContentGeneration';
import {
  getCurrentSelectedLanguage,
  getEncodedUserNtid,
  getParamsUsed,
} from '../../../store/markettingContentGeneration/helpers/common';
import {
  // ContentResult,
  ContentGeneratedResult,
  GeneratedContentData,
  GeneratedContentResultData,
} from '../../../types/Content';
import { getContentGroupId, getSessionId } from '../../../utils/commonUtils';
import { trackGenerateContentComplete } from '../../../services/Analytics';
import { currentSelectedLanguageStateSegment } from '../../../store/markettingContentGeneration/stateSegments/availableLanguagesStateSegment';
import { translateRefineContent } from '../../../store/markettingContentGeneration/actions/microProcessActions';
import { contentPaginationStateSegment } from '../../../store/markettingContentGeneration/stateSegments/contentPaginationStateSegment';
import { getGeneratedContentValidations } from '../../../store/markettingContentGeneration/actions/microProcessActions/getGeneratedContentValidation';

const BackgroundTaskManager: React.FC = () => {
  const [fileUploadValue] = useSegment(fileUploadSegment);
  const generateValue = useSegmentValue(generateStateSegment);
  const initializer = useRef(false);
  const fileUploadValueRef = useRef(fileUploadValue);

  const { fileUploading, fileUploaded, error, success } =
    useSegmentValue(fileUploadSegment);

  const {
    showSnackBar,
    isSnackBarVisible,
    hideSnackBar,
    notificationText,
    snackBarTheme,
  } = useSnackBar();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): string | void => {
      event.preventDefault();
      if (fileUploading) {
        const message =
          'Leaving this page will terminate your file uploading process. Are you sure?';
        // eslint-disable-next-line no-param-reassign
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
      // Explicitly return undefined when there is no need to show a warning
      return undefined;
    };
    if (fileUploaded) {
      window.removeEventListener('beforeunload', () => undefined);
    }
    // Add event listener for beforeunload
    if (fileUploading) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [fileUploading, fileUploaded]);

  useEffect(() => {
    fileUploadValueRef.current = fileUploadValue;
  }, [fileUploadValue]);

  useEffect(() => {
    if (success) {
      showSnackBar(success, SnackBarVariant.SUCCESS);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      showSnackBar(error, SnackBarVariant.ERROR);
    }
  }, [error]);

  const validateFileProcessCb = (): void => {
    validateFileProcess();
    window.uploadInterval = setInterval(() => {
      const { fileProcessed, fileProcessingError, fileProcessing } =
        fileUploadValueRef.current;
      if (!fileProcessed && !fileProcessingError && fileProcessing) {
        validateFileProcess();
      } else {
        if (window.uploadInterval) {
          clearInterval(window.uploadInterval);
        }
        window.uploadInterval = null;
      }
    }, 60000);
  };

  useEffect(() => {
    // Initial session check
    if (!initializer.current) {
      initializer.current = true;
      getSessionDetails(SUMMARIZE)
        .then((sessionId: string) => {
          // Polling file process status
          if (sessionId) {
            validateFileProcessCb();
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);
        });
    }

    return () => {
      clearInterval(window.uploadInterval);
      window.uploadInterval = null;
    };
  }, []);

  useEffect(() => {
    if (
      !window.uploadInterval &&
      fileUploadValue.sessionId &&
      !fileUploadValue.fileProcessed &&
      fileUploadValue.fileProcessing
    ) {
      validateFileProcessCb();
    }
  }, [fileUploadValue]);

  const successfulContentGeneration = ({
    sessionId,
    action,
    channel,
    getGeneratedContentData,
  }: {
    sessionId: string;
    channel: string;
    action: string;
    getGeneratedContentData?: GeneratedContentData[];
  }) => {
    const {
      primaryLanguage,
      paramsUsed,
      apiPayloadUsed,
      isRefineContent,
      isReGeneratingContent,
    } = generateValue;
    if (window.uploadInterval) {
      clearInterval(window.uploadInterval);
    }
    store.set(generateStateSegment, RESET);
    store.set(contentListStateSegment, (prev) => {
      const content = {
        [primaryLanguage]: getGeneratedContentFormatted(ContentType.DSP, {
          generatedContent: {
            sessionId,
            action,
            channel,
            ...getGeneratedContentData,
          },
        } as unknown as ContentGeneratedResult['data'])?.data,
        paramsUsed: getParamsUsed(paramsUsed),
        regenerationLimit: REGENERATION_LIMIT - 1,
        apiPayloadUsed,
      };

      if (isRefineContent) {
        return {
          ...prev,
          isRefiningContent: false,
          contentList: [...prev.contentList, content] as never,
        };
      }

      if (isReGeneratingContent) {
        return {
          ...prev,
          isReGeneratingContent: false,
          errorReGeneratingContent: false,
          errorMessage: '',
          isGeneratingContent: false,
          contentList: [...prev.contentList, content] as never,
        };
      }
      return {
        ...prev,
        errorGeneratingContent: false,
        errorMessage: '',
        isGeneratingContent: false,
        contentList: [content] as never,
      };
    });
    if (isRefineContent) {
      const currentSelectedLanguage = store.get(
        currentSelectedLanguageStateSegment
      );
      const currentViewVersion = store.get(contentPaginationStateSegment);
      if (
        currentSelectedLanguage &&
        currentSelectedLanguage !== getCurrentSelectedLanguage() &&
        success
      ) {
        translateRefineContent(
          currentViewVersion,
          currentSelectedLanguage,
          action || '',
          paramsUsed?.currentSelectedDestinationFormat
        );
      }
    }
    window.uploadInterval = null;
  };

  const generateContent = async () => {
    const {
      paramsUsed,
      apiPayloadUsed,
      contentId,
      isRefineContent,
      isReGeneratingContent,
    } = generateValue;
    const {
      success: generateStatusSuccess,
      data: { generatedContentStatus = {} } = {},
    } = await generateStatus(apiPayloadUsed, contentId);
    if (generateStatusSuccess) {
      const {
        contentSummary: { summarizationStatus = '' },
      } =
        (Array.isArray(generatedContentStatus) && generatedContentStatus[0]) ||
        {};
      if (summarizationStatus === summarizationStatuses.SUMMARIZATION_ERROR) {
        if (window.uploadInterval) {
          clearInterval(window.uploadInterval);
        }
        store.set(generateStateSegment, RESET);
        store.set(contentListStateSegment, (prev) => {
          return {
            ...prev,
            ...(isRefineContent ? { isRefiningContent: false } : {}),
            ...(isReGeneratingContent ? { isReGeneratingContent: false } : {}),
            ...(!isRefineContent && !isReGeneratingContent
              ? { isGeneratingContent: false }
              : {}),
            errorGeneratingContent: true,
            errorMessage: 'There has been an error in generating content',
          };
        });
      }
      if (
        summarizationStatus === summarizationStatuses.SUMMARIZATION_COMPLETED
      ) {
        const { success: getGeneratedContentSuccess, data } =
          await getGeneratedContent({
            sessionId: apiPayloadUsed.sessionId || getSessionId(),
            contentGroupId:
              apiPayloadUsed.contentGroupId || getContentGroupId(),
            contentId,
            action: paramsUsed.currentSelectedAction.toLowerCase(),
          });
        const {
          sessionId,
          action,
          channel,
          generatedContent: getGeneratedContentData,
        } = data as GeneratedContentResultData;
        if (getGeneratedContentSuccess) {
          successfulContentGeneration({
            sessionId,
            action,
            channel,
            getGeneratedContentData:
              getGeneratedContentData as GeneratedContentData[],
          });
          // fetch generated content validation
          getGeneratedContentValidations({
            contentId,
            action: paramsUsed.currentSelectedAction.toLowerCase(),
            sessionId: apiPayloadUsed.sessionId || getSessionId(),
            contentGroupId:
              apiPayloadUsed.contentGroupId || getContentGroupId(),
          });

          if (isReGeneratingContent) {
            trackGenerateContentComplete(ANALYTICS.REGENERATE_FORM_API_NAME);
            trackGenerateContentComplete(
              ANALYTICS.REGENERATE_FORM_NAME,
              paramsUsed.currentSelectedBrand.toLowerCase(),
              paramsUsed.currentSelectedContentType.toLowerCase(),
              getEncodedUserNtid()
            );
          } else {
            trackGenerateContentComplete(ANALYTICS.GENERATE_FORM_API_NAME);
            trackGenerateContentComplete(
              ANALYTICS.GENERATE_FORM_NAME,
              paramsUsed.currentSelectedBrand.toLowerCase(),
              paramsUsed.currentSelectedContentType.toLowerCase(),
              getEncodedUserNtid()
            );
          }
        }
      }
    } else {
      clearInterval(window.uploadInterval);
      store.set(contentListStateSegment, (prev) => {
        return {
          ...prev,
          ...(isRefineContent ? { isRefiningContent: false } : {}),
          ...(isReGeneratingContent ? { isReGeneratingContent: false } : {}),
          ...(!isRefineContent && !isReGeneratingContent
            ? { isGeneratingContent: false }
            : {}),
          errorGeneratingContent: true,
          errorMessage: 'There has been an error in generating content',
        };
      });
    }
  };

  useEffect(() => {
    const { apiPayloadUsed, generateContentInitiated } = generateValue;
    if (
      apiPayloadUsed.brand &&
      (apiPayloadUsed.sessionId || getSessionId()) &&
      generateContentInitiated
    ) {
      window.uploadInterval = setInterval(generateContent, 20000);
    }

    return () => {
      clearInterval(window.uploadInterval);
      window.uploadInterval = null;
    };
  }, [generateValue.generateContentInitiated]);

  if (isSnackBarVisible) {
    return (
      <SnackBar
        theme={snackBarTheme}
        notificationText={notificationText}
        hideSnackBar={hideSnackBar}
        position={SnackBarPosition.BOTTOM_CENTER}
      />
    );
  }

  return null;
};

export default BackgroundTaskManager;
