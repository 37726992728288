import { Dispatch, SetStateAction } from 'react';
import get from 'lodash/get';
import { ActiveEmailModule } from '../../../../types/store/markettingContentGeneration/emailModules';
import {
  getContent,
  getContentApiPayloadUsed,
  getCurrentSelectedLanguage,
  getEncodedUserNtid,
  getVersionIds,
  resetOptionImages,
  showLoadersBasedOnContentType,
} from '../../helpers/common';
import { ANALYTICS, ContentType } from '../../../../constants/common';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import store from '../../index';
import { fetchPartialRegeneratedContent } from '../../../../services/Content';
import {
  trackConfidenceScore,
  trackConfidenceScoreForPrimaryMessage,
  trackContentConfidenceScore,
  trackFormComplete,
  trackFormError,
  trackFormInitiate,
} from '../../../../services/Analytics';
import { moduleContentsStateSegment } from '../../stateSegments/emailModulesStateSegment';
import { currentSelectedLanguageStateSegment } from '../../stateSegments/availableLanguagesStateSegment';
import { partialTranslateContent } from './partialTranslateContent';
import { getGeneratedContentValidations } from './getGeneratedContentValidation';
import { getContentGroupId, getSessionId } from '../../../../utils/commonUtils';

export const partialRegeneration = async (
  params: {
    currentVersion: number;
    langCode: string;
    sections: string[];
    sectionId: string; // its option id itself
    loaderPath: string;
    activeModuleMapKey: string;
    setActiveModuleDetails: Dispatch<SetStateAction<ActiveEmailModule>>;
  },
  callback: (success: boolean) => void
) => {
  const {
    currentVersion,
    sections,
    sectionId,
    activeModuleMapKey,
    setActiveModuleDetails,
  } = params;
  const contentCurrentSelectedLanguage = getCurrentSelectedLanguage();

  // get current content
  const content = getContent(currentVersion);
  // get current content id
  const contentId = get(
    content,
    `${contentCurrentSelectedLanguage}.id`,
    ''
  ) as string;
  // get payload used to generate whole content
  const apiPayloadUsed = getContentApiPayloadUsed(content);

  // extract optionId based on content type
  const optionId = sectionId;
  const contentType = get(apiPayloadUsed, 'contentType');

  // clear recommendedimages if partial regenerate is invoked
  resetOptionImages(contentId, optionId);

  // show section wise loaders based on content type

  showLoadersBasedOnContentType({
    contentType: contentType as ContentType,
    optionId,
    currentVersion,
    loaderPath: params.loaderPath,
    showLoader: true,
    langCode: contentCurrentSelectedLanguage,
  });

  // contruct partial regeneration related payload
  const regenerationDetails = {
    optionId,
    sections,
  };

  apiPayloadUsed.contentId = contentId;

  const versionIds = getVersionIds();

  const [firstContentId] = versionIds;
  apiPayloadUsed.parentId = firstContentId;

  apiPayloadUsed.versionIds = versionIds;

  // apiPayloadUsed.toneStyle = convertTextToLowerCase(toneStyleOptions[1].name); // balanced default
  // apiPayloadUsed.exclusions = '';

  const { brand, topics } = apiPayloadUsed;

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isPartialRegenerating: true,
    };
  });

  const { success, data } = await fetchPartialRegeneratedContent(
    apiPayloadUsed,
    regenerationDetails
  );
  const newContentId = get(data, 'id', '');
  // Analytics: track partial regenerate api intiate
  trackFormInitiate(ANALYTICS.PARTIAL_REGENERATE_API_FORM_NAME);

  if (success) {
    // Analytics: track partial regenerate api success
    trackFormComplete(ANALYTICS.PARTIAL_REGENERATE_API_FORM_NAME);

    // create an replica for mutation
    const contentItems = store.get(contentListStateSegment).contentList;
    const contentListReplica = [...contentItems];
    const currentContent = contentListReplica[currentVersion];

    /* eslint-disable */
    if (currentContent && currentContent['regenerationLimit'] > 0) {
      (currentContent['regenerationLimit'] as number) -= 1;
    }

    // update partial regenerated content
    const languageContent: any = currentContent[contentCurrentSelectedLanguage];
    if (contentType === ContentType.DSP) {
      languageContent['markdown'] = data?.markdownText;
    } else {
      languageContent['content'] = data?.content;
    }

    languageContent['score'] = data?.score;
    languageContent.id = data?.id;

    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isPartialRegenerating: false,
        contentList: [...contentListReplica],
      };
    });
    callback(true);

    // track confidence score for partial regenerate content / brand and primary message
    if (data?.score?.value) {
      trackContentConfidenceScore(
        ANALYTICS.PARTIAL_REGENERATE_FORM_NAME,
        data?.score?.value,
        1 // index for confidence score question
      );
      trackConfidenceScore(
        ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
        brand,
        getEncodedUserNtid(),
        data?.score?.value
      );
      if (topics) {
        trackConfidenceScoreForPrimaryMessage(
          ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
          [topics],
          data?.score?.value
        );
      }
    }
    if (apiPayloadUsed.contentType === ContentType.EMAIL) {
      const moduleContents = store.get(moduleContentsStateSegment);
      const module = moduleContents.get(activeModuleMapKey);
      if (module && module.selectedOption?.content?.optionId === optionId) {
        moduleContents.set(activeModuleMapKey, {
          ...module,
          hasImageSelected: false,
          hasOptionSelected: false,
          selectedContentVersion: null,
          selectedOptionLanguage: '',
          selectedOption: null,
          selectedOptionId: '',
          selectedImage: null,
          selectedImageName: '',
          status: 'In Progress',
        });
        store.set(moduleContentsStateSegment, moduleContents);
        setActiveModuleDetails((prev) => ({
          ...prev,
          selectedContentVersion: null,
          status: 'In Progress',
        }));
      }
    }
  } else {
    // Analytics: track partial regenerate api failure
    trackFormError(
      ANALYTICS.PARTIAL_REGENERATE_API_FORM_NAME,
      'partial regenerate api failed'
    );

    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isPartialRegenerating: false,
        errorPartialRegenerating: true,
        errorMessage: 'There has been an error in regenerating this section',
      };
    });
    callback(false);
  }
  // hide section loaders
  showLoadersBasedOnContentType({
    contentType: contentType as ContentType,
    optionId,
    currentVersion,
    loaderPath: params.loaderPath,
    showLoader: false,
    langCode: contentCurrentSelectedLanguage,
  });

  const currentSelectedLanguage = store.get(
    currentSelectedLanguageStateSegment
  );

  if (
    currentSelectedLanguage &&
    currentSelectedLanguage !== contentCurrentSelectedLanguage &&
    success
  ) {
    const { action } = apiPayloadUsed;
    partialTranslateContent(
      currentVersion,
      currentSelectedLanguage,
      optionId,
      sections,
      params.loaderPath,
      action,
      apiPayloadUsed.destinationFormat
    );
  }

  // fetch generated content validation
  getGeneratedContentValidations({
    contentId: newContentId,
    action: get(apiPayloadUsed, 'action', ''),
    sessionId: getSessionId(),
    contentGroupId: getContentGroupId(),
  });
};
