import store from '../..';
import { imageCaptionStateSegment } from '../../stateSegments/imageCaptionStateSegment';

export const updateImageCaptionLoaders = ({
  isLoadingImageCaption,
  errorLoadingImageCaption,
}: {
  isLoadingImageCaption: boolean;
  errorLoadingImageCaption: boolean;
}) => {
  store.set(imageCaptionStateSegment, (prev) => {
    return {
      ...prev,
      errorLoadingImageCaption,
      isLoadingImageCaption,
    };
  });
};

export const updateImageCaptionOptionId = (imageCaptionOptionId: string) => {
  store.set(imageCaptionStateSegment, (prev) => {
    return {
      ...prev,
      selectedOptionId: imageCaptionOptionId,
    };
  });
};
