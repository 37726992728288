import { useState, useEffect } from 'react';
import { SnackBarVariant } from '../../components/atoms/SnackBar';

export const useSnackBar = (delayinMilliSeconds = 4000, autoHide = true) => {
  const [isSnackBarVisible, setIsSnackBarVisible] = useState(false);
  const [notificationText, setNotificationText] = useState('');
  const [snackBarTheme, setSnackBarTheme] = useState<SnackBarVariant>(
    SnackBarVariant.NEUTRAL
  );
  const [titleText, setTitleText] = useState('');

  const showSnackBar = (
    text: string,
    theme: SnackBarVariant = SnackBarVariant.NEUTRAL,
    title?: string
  ) => {
    setNotificationText(text);
    setIsSnackBarVisible(true);
    setSnackBarTheme(theme);
    setTitleText(title || '');
  };

  const hideSnackBar = () => {
    setIsSnackBarVisible(false);
  };

  useEffect(() => {
    if (autoHide) {
      const timer = setTimeout(() => {
        hideSnackBar();
      }, delayinMilliSeconds); // Adjust the delay in milliseconds as needed
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [isSnackBarVisible, autoHide]);

  return {
    titleText,
    showSnackBar,
    isSnackBarVisible,
    hideSnackBar,
    notificationText,
    snackBarTheme,
  };
};
