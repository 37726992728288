import get from 'lodash/get';
import store from '../..';
import { GenerateContentValidationStateSegment } from '../../stateSegments/GenerateContentValidationStateSegment';
import { fetchGeneratedContentValidation } from '../../../../services/Content';

export const getGeneratedContentValidations = async ({
  contentId,
  sessionId,
  contentGroupId,
  action,
}: {
  contentId: string;
  sessionId: string;
  contentGroupId: string;
  action: string;
}) => {
  store.set(GenerateContentValidationStateSegment, (prev) => {
    return {
      ...prev,
      [contentId]: {
        ...prev[contentId],
        isLoading: true,
      },
    };
  });

  const { data, success } = await fetchGeneratedContentValidation({
    contentId,
    sessionId,
    contentGroupId,
    action,
  });

  if (data && success) {
    const tempValidationMap: Record<
      string,
      { errors: string[]; hasValidationsComplete: boolean }
    > = {};

    const currentContentValidation = get(
      data,
      'generatedContentValidation[0]',
      { validations: [] }
    );
    const validations = get(currentContentValidation, 'validations', []);

    validations.forEach((validation) => {
      const type = get(validation, 'type', '');
      tempValidationMap[type] = {
        errors: get(validation, 'errors', []),
        hasValidationsComplete: get(validation, 'success', false),
      };
    });
    store.set(GenerateContentValidationStateSegment, (prev) => {
      return {
        ...prev,
        [contentId]: {
          ...prev[contentId],
          error: false,
          errorMessage: '',
          isLoading: false,
          validations: tempValidationMap,
          retryParams: {
            contentId,
            sessionId,
            contentGroupId,
            action,
          },
        },
      };
    });
  } else {
    store.set(GenerateContentValidationStateSegment, (prev) => {
      return {
        ...prev,
        [contentId]: {
          ...prev[contentId],
          error: true,
          errorMessage: 'Failed to load quality checks',
          isLoading: false,
          validation: {},
          retryParams: {
            contentId,
            sessionId,
            contentGroupId,
            action,
          },
        },
      };
    });
  }
};
