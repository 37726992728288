import { lazy, Suspense } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
// import { Provider as JotaiProvider } from 'jotai';
import { SSOProvider } from './SSOProvider';
import SSOCallback from '../components/pages/SSOCallback';
// import Home from '../components/pages/Home';
import { PAGE_ROUTES } from '../constants/common';
import MarketingApplication from '../components/pages/MarketingApplication';
import PageLoader from '../components/molecules/PageLoader';
// import markettingContentGenerationStore from '../store/markettingContentGeneration';

const ContentQualityCheck = lazy(
  () => import('../components/pages/ContentQualityCheck')
);

const JotaiDebugHooks = lazy(
  () => import('../components/widgets/JotaiDebugHooks')
);

const shouldShowDebugger =
  sessionStorage.getItem('pfz-cggenai-debug-secret') ===
  process.env.REACT_APP_STATE_DEBUG_KEY;

export const router = createBrowserRouter([
  {
    path: PAGE_ROUTES.DEFAULT,
    element: <Navigate to={PAGE_ROUTES.HOME} replace />,
  },
  {
    path: PAGE_ROUTES.HOME,
    element: (
      <Suspense fallback={<PageLoader />}>
        <SSOProvider>
          <MarketingApplication isLoadHomeWidget key="home" />
        </SSOProvider>
      </Suspense>
    ),
  },
  {
    path: PAGE_ROUTES.GENERATE_CONTENT,
    element: (
      <Suspense fallback={<PageLoader />}>
        <SSOProvider>
          {/* <JotaiProvider store={markettingContentGenerationStore}> */}
          {shouldShowDebugger && <JotaiDebugHooks />}
          <MarketingApplication isLoadMarketingWidget key="create-content" />
          {/* </JotaiProvider> */}
        </SSOProvider>
      </Suspense>
    ),
    children: [
      {
        path: PAGE_ROUTES.QUALITY_VALIDATION,
        element: <ContentQualityCheck />,
      },
    ],
  },
  {
    path: PAGE_ROUTES.ACCESS_MANAGEMENT,
    element: (
      <Suspense fallback={<PageLoader />}>
        <SSOProvider>
          <MarketingApplication isLoadAccessWidget key="access-management" />
        </SSOProvider>
      </Suspense>
    ),
  },
  {
    path: PAGE_ROUTES.UPLOAD_GENERATE,
    element: (
      <Suspense fallback={<PageLoader />}>
        <SSOProvider>
          {shouldShowDebugger && <JotaiDebugHooks />}
          <MarketingApplication isLoadUploadView key="upload-and-create" />
        </SSOProvider>
      </Suspense>
    ),
  },
  {
    path: PAGE_ROUTES.QUALITY_VALIDATION,
    element: (
      <Suspense fallback={<PageLoader />}>
        <SSOProvider>
          <MarketingApplication
            // isLoadQualiltyCheck
            key="content-quality-validation"
          />
        </SSOProvider>
      </Suspense>
    ),
  },
  {
    path: PAGE_ROUTES.CALLBACK,
    element: <SSOCallback />,
  },
  {
    path: PAGE_ROUTES.ANY,
    element: <Navigate to="/" replace />,
  },
]);
