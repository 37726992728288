import get from 'lodash/get';
import { convertTextToLowerCase } from '../../../../utils/convertTextToLowerCase';
import store from '../../index';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { contentTranslationStateSegment } from '../../stateSegments/translationSegment';
import { fetchTranslatedContent } from '../../../../services/Content';
import { ContentType } from '../../../../constants/common';
import {
  doesImageCaptionExists,
  doesImageRecommendExists,
  getCurrentSelectedLanguage,
} from '../../helpers/common';
import { updateTranslatedCaptionOnImageSegment } from './updateTranslatedCaption';

export const translateRefineContent = async (
  currentViewVersion: number,
  languageCode: string,
  action: string,
  destinationFormat?: string
) => {
  const currentData = store.get(contentListStateSegment).contentList[
    currentViewVersion + 1
  ];
  const contentType = convertTextToLowerCase(
    get(currentData, 'paramsUsed.contentType.name', '')
  );

  const contentId = get(currentData, `${getCurrentSelectedLanguage()}.id`);

  store.set(contentTranslationStateSegment, (prev) => {
    return { ...prev, isTranslatingContent: true };
  });

  const { success, data } = await fetchTranslatedContent({
    contentId,
    languageId: languageCode,
    contentType,
    action,
    destinationFormat,
  });

  if (success && data) {
    store.set(contentTranslationStateSegment, (prev) => {
      return {
        ...prev,
        isTranslatingContent: false,
        map: {
          ...prev.map,
          [contentId]: {
            ...prev.map[contentId],
            langCode: languageCode,
            data,
          },
        },
      };
    });

    if (
      contentType === ContentType.EMAIL &&
      doesImageCaptionExists(contentId) &&
      doesImageRecommendExists(data)
    ) {
      updateTranslatedCaptionOnImageSegment(contentId, data);
    }
  } else {
    store.set(contentTranslationStateSegment, (prev) => {
      return {
        ...prev,
        errorTranslatingContent: true,
        isTranslatingContent: false,
        map: {
          ...prev.map,
          [contentId]: {
            ...prev.map[contentId],
            retryParams: {
              currentViewVersion,
              langCode: languageCode,
            },
          },
        },
      };
    });
  }
};
