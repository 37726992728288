import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import { ParamsUsed } from '../../../types/store/markettingContentGeneration/majorProcessActions';
import {
  MarketingFormFieldRequest,
  PregenerateClaimsFeild,
  SecondaryFormApiField,
  VersionPayload,
} from '../../../types/MarketingBox';

export type ApiPayloadUsed =
  | (PregenerateClaimsFeild & MarketingFormFieldRequest)
  | (MarketingFormFieldRequest & VersionPayload & { sessionId: string })
  | (MarketingFormFieldRequest & SecondaryFormApiField & VersionPayload);

type Generate = {
  primaryLanguage: string;
  paramsUsed: ParamsUsed;
  contentId: string;
  isRefineContent?: boolean;
  isReGeneratingContent?: boolean;
  generateContentInitiated: boolean;
  apiPayloadUsed: ApiPayloadUsed;
  contentListReplica?: never[];
};

export const generateStateSegment = stateSegmentWithReset<Generate>({
  primaryLanguage: '',
  paramsUsed: {
    currentAvailableActionOptions: [],
    currentAvailableBrandOptions: [],
    currentAvailableIndicationOptions: [],
    currentAvailableCountryOptions: [],
    currentAvailableAudienceOptions: [],
    currentAvailableContentTypeOptions: [],
    currentAvailableSegmentOptions: [],
    currentAvailableSectionOptions: [],
    currentAvailableToneOptions: [],
    currentAvailablePlatformOptions: [],
    currentAvailableTopicOptions: [],
    currentAvailableToneStyleOptions: [],
    currentSelectedAction: '',
    currentSelectedBrand: '',
    currentSelectedIndication: '',
    currentSelectedCountry: '',
    currentSelectedAudience: '',
    currentSelectedContentType: '',
    currentSelectedSegment: '',
    currentSelectedPlatform: '',
    currentSelectedSections: [],
    currentSelectedTone: '',
    currentSelectedTopic: [],
    currentSelectedToneStyle: '',
    currentSelectedExclusion: [],
    currentSelectedInclusion: [],
    currentSelectedModules: '',
    currentSelectedFile: '',
    currentSelectedLanguage: '',
    currentAvailableLanguageOptions: [],
  },
  apiPayloadUsed: {
    brand: '',
    contentType: '',
    topics: [],
    targetAudience: '',
    sessionId: '',
    useSubstituteClaims: false,
    claims: [],
    action: '',
    indication: '',
    country: '',
    segment: '',
    sections: '',
    tone: '',
    platform: '',
    emailModules: '',
    fileName: '',
    contentGroupId: '',
    language: '',
  },
  isRefineContent: false,
  isReGeneratingContent: false,
  generateContentInitiated: false,
  contentId: '',
  contentListReplica: [],
});

generateStateSegment.debugLabel = 'generateStateSegment';
