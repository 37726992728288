import { atom as stateSegment } from 'jotai';

type GenerateContentValidation = Record<
  string,
  {
    isLoading: boolean;
    error?: boolean;
    errorMessage?: string;
    validations: Record<
      string,
      { errors: string[]; hasValidationsComplete: boolean }
    >;
    retryParams: {
      contentId: string;
      sessionId: string;
      contentGroupId: string;
      action: string;
    };
  }
>;

export const GenerateContentValidationStateSegment =
  stateSegment<GenerateContentValidation>({});

GenerateContentValidationStateSegment.debugLabel =
  'GenerateContentValidationStateSegment';
