import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

type ImageCaption = {
  isLoadingImageCaption: boolean;
  errorLoadingImageCaption: boolean;
  selectedOptionId: string;
};

export const imageCaptionStateSegment = stateSegmentWithReset<ImageCaption>({
  isLoadingImageCaption: false,
  errorLoadingImageCaption: false,
  selectedOptionId: '',
});
imageCaptionStateSegment.debugLabel = 'imageCaptionStateSegment';
